import React from "react"
// import { Link } from "gatsby"

import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import "../components/style.scss"

import {
  MdClose,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
} from "react-icons/md"

export const query = graphql`
  query($Slug: String) {
    sanityCases(slug: { current: { eq: $Slug } }) {
      seo {
        seo_title
        meta_description
      }
      title
      subTitle
      _rawBody
      mainImage {
        alt
        image {
          asset {
            fluid(maxHeight: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sitePage(context: { Slug: { eq: $Slug } }) {
      context {
        pageNumber
        prev {
          slug {
            current
          }
        }
        next {
          slug {
            current
          }
        }
      }
    }
    allSanityCases {
      pageInfo {
        itemCount
      }
    }
  }
`

const Cases = props => {
  return (
    <section className="case">
      {props.data.sanityCases.seo && (
        <SEO
          title={props.data.sanityCases.seo.seo_title}
          metaDescription={props.data.sanityCases.seo.meta_description}
          seoTitle={props.data.sanityCases.seo.seo_title}
        />
      )}
      <div className="case_wrapper">
        <div className="case_grid">
          <Img
            onLoad={numberLoaded}
            fluid={props.data.sanityCases.mainImage.image.asset.fluid}
            alt={props.data.sanityCases.mainImage.alt}
          ></Img>

          <div className="text_content">
            <Link className="close" to="/cases/">
              <MdClose />
            </Link>
            <p className="subtitle">{props.data.sanityCases.subTitle}</p>

            <BlockContent
              className={props.data.sanityCases.templateList}
              blocks={props.data.sanityCases._rawBody}
              projectId="ppd47c0u"
              dataset="production"
              serializers={serializers}
            />
          </div>
        </div>

        <div className="directions">
          {props.data.sitePage.context.prev && (
            <Link
              to={`/${props.data.sitePage.context.prev.slug.current}/`}
              className="left"
            >
              <MdKeyboardArrowLeft />
            </Link>
          )}
          <span className="math">
            {props.data.sitePage.context.pageNumber}{" "}
          </span>
          <span>/</span>
          <span> {props.data.allSanityCases.pageInfo.itemCount}</span>
          <span className="title">{props.data.sanityCases.title}</span>
          {props.data.sitePage.context.next && (
            <Link
              to={`/${props.data.sitePage.context.next.slug.current}/`}
              className="right"
            >
              <MdKeyboardArrowRight />
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

export default Cases

function numberLoaded() {
  document.querySelector(".directions").style.opacity = "1"

  var math = parseInt(document.querySelector(".math").innerHTML)
  var one = 1
  const page = math + one

  document.querySelector(".math").innerHTML = page
}
